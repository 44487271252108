import { AbstractIndicatorGroup } from "./abstractIndicatorGroup";
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { SphereBuilder } from "@babylonjs/core/Meshes/Builders/sphereBuilder";
import { TorusBuilder } from '@babylonjs/core/Meshes/Builders/torusBuilder';
export class ShapeIndicatorGroup extends AbstractIndicatorGroup {
    constructor(shapeDefinition, scene) {
        const indicSrcMesh = shapeDefinition._generateIndicSrcMesh(scene);
        super(indicSrcMesh, scene);
    }
}
export class SphereIndicatorShapeDefinition {
    constructor(diameter, color, alpha, material, segments, pickable) {
        this.diameter = diameter;
        this.color = color;
        this.alpha = alpha;
        this.material = material;
        this.segments = segments;
        this.pickable = pickable;
    }
    /**
     * (internal, do not use)
     * @hidden
     */
    _generateIndicSrcMesh(scene) {
        const indicSrcMesh = SphereBuilder.CreateSphere("SphereIndicatorShapeDefinition_generated_indicSrcMesh", {
            diameter: this.diameter, segments: this.segments
        }, scene);
        indicSrcMesh.isPickable = Boolean(this.pickable);
        if (this.material) {
            indicSrcMesh.material = this.material;
        }
        else if (this.color) {
            const mat = new StandardMaterial("SphereIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            mat.diffuseColor.copyFromFloats(0, 0, 0);
            mat.specularColor.copyFromFloats(0, 0, 0);
            mat.emissiveColor.copyFrom(this.color);
            if (this.alpha != undefined)
                mat.alpha = this.alpha;
            indicSrcMesh.material = mat;
        }
        return indicSrcMesh;
    }
}
export class TorusIndicatorShapeDefinition {
    constructor(diameter, thickness, color, alpha, material, tessellation, pickable, locked, clickable) {
        this.diameter = diameter;
        this.thickness = thickness;
        this.color = color;
        this.alpha = alpha;
        this.material = material;
        this.tessellation = tessellation;
        this.pickable = pickable;
        this.locked = locked;
        this.clickable = clickable;
    }
    /**
     * (internal, do not use)
     * @hidden
     */
    _generateIndicSrcMesh(scene) {
        var indicSrcMesh;
        var btnMesh;
        const tempSrcMesh = TorusBuilder.CreateTorus("TorusIndicatorShapeDefinition_generated_indicSrcMesh", {
            diameter: this.diameter, thickness: this.thickness, tessellation: this.tessellation
        }, scene);
        if (this.material) {
            tempSrcMesh.material = this.material;
        }
        else if (this.color) {
            const mat = new StandardMaterial("TorusIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            mat.diffuseColor.copyFromFloats(0, 0, 0);
            mat.specularColor.copyFromFloats(0, 0, 0);
            mat.emissiveColor.copyFrom(this.color);
            if (this.alpha != undefined)
                mat.alpha = this.alpha;
            tempSrcMesh.material = mat;
        }
        console.log("locked?", this.locked);
        if (this.clickable) {
            const btnMesh = SphereBuilder.CreateSphere("SphereIndicatorShapeDefinition_generated_indicSrcMesh", {
                diameter: this.diameter, segments: this.tessellation
            }, scene);
            btnMesh.isPickable = Boolean(this.pickable);
            const btMat = new StandardMaterial("SphereIndicatorShapeDefinition_generated_indicSrcMesh_material", scene);
            btMat.alpha = .0000000001;
            btnMesh.material = btMat;
            // tempSrcMesh.isPickable = false;
            //btnMesh.parent = tempSrcMesh;
            indicSrcMesh = Mesh.MergeMeshes([tempSrcMesh, btnMesh], true, true, undefined, false, true);
        }
        else {
            indicSrcMesh = tempSrcMesh;
        }
        if (this.locked) {
            indicSrcMesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
        }
        indicSrcMesh.isPickable = Boolean(this.pickable);
        return indicSrcMesh;
    }
}
