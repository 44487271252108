import { DefaultLoadingScreen } from "@babylonjs/core";
import gif from "../../public/loading.gif";
export function showLoadingUI() {
    DefaultLoadingScreen.prototype.displayLoadingUI = function () {
        if (document.getElementById("customLoadingScreenDiv")) {
            document.getElementById("customLoadingScreenDiv").style.display = "initial";
            return;
        }
        this._loadingDivBackgroundColor = "black";
        this._loadingDiv = document.createElement("div");
        const gifDiV = document.createElement("div");
        gifDiV.innerHTML = "<img src=" + gif + "/>";
        this._loadingDiv.id = "customLoadingScreenDiv";
        this._loadingDiv.className = "loadingLayer";
        this._loadingDiv.appendChild(gifDiV);
        window.addEventListener("resize", this._resizeLoadingUI);
        document.body.appendChild(this._loadingDiv);
    };
}
export function HideLoadingUI() {
    DefaultLoadingScreen.prototype.hideLoadingUI = function () {
        document.getElementById("customLoadingScreenDiv").style.display = "none";
        document.getElementById("customLoadingScreenDiv").classList.add("is-hidden");
        document.getElementById("gifLayer").classList.add("is-hidden");
    };
}
