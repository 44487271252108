import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { EnvironmentHelper } from "@babylonjs/core/Helpers/environmentHelper";
// required imports
import "@babylonjs/core/Loading/loadingScreen";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Materials/standardMaterial";
import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import "@babylonjs/core/Animations/animatable";
// digital assets
import sceneGLB from "../../assets/glb/car2.glb";
import skyEnvironment from "../../assets/environment/env5.env";
import { ActionManager, Color3, ExecuteCodeAction, StandardMaterial, Animation, Color4, CircleEase, MirrorTexture, Plane } from "@babylonjs/core";
import { ShapeIndicatorGroup, SphereIndicatorShapeDefinition } from "../lib/indication/shapeIndicatorGroup";
import { annoFunction } from "../lib/annotations/annoFunction";
import { setupToolbox } from "../scene/toolbox";
import { HideLoadingUI, showLoadingUI } from "../scene/CustomLoadingScreen";
import { cameraValuesChange, changeCamera } from "../lib/cameras/cameraChange";
import { setupUiContainer } from "../scene/UiContainer";
//INIT LOADING LAYER 
showLoadingUI();
HideLoadingUI();
export class AppModeController {
    constructor() {
        this._createScene = async (engine, canvas) => {
            engine.displayLoadingUI();
            const scene = new Scene(engine);
            void Promise.all([
                import("@babylonjs/core/Debug/debugLayer"),
                import("@babylonjs/inspector"),
            ]).then((_values) => {
                console.log(_values);
                window.addEventListener("keydown", (e) => {
                    if (e.key === 'q') {
                        if (scene.debugLayer.isVisible()) {
                            scene.debugLayer.hide();
                        }
                        else {
                            scene.debugLayer.show({
                                handleResize: true,
                                overlay: true,
                                globalRoot: document.getElementById("#root") || undefined,
                            });
                        }
                    }
                });
                setupUiContainer(scene);
                setupToolbox(scene);
            });
            ///////////////////////
            // INITIAL VARIABLES //
            ///////////////////////
            var backButton = document.getElementById("backButton");
            var dodgeLogo = document.getElementById("logo");
            var packButton = document.getElementById("packBtn");
            var whiteExButton = document.getElementById("exterior_white");
            var redExButton = document.getElementById("exterior_red");
            var greyExButton = document.getElementById("exterior_grey");
            var interiorH2 = document.getElementById("interiorH");
            var exteriorH2 = document.getElementById("exteriorH");
            var carWrapper = document.getElementById("car-wrapper");
            var redInButton = document.getElementById("interior_red");
            var blackInButton = document.getElementById("interior_black");
            var frogNode;
            var carNode;
            var carNodeMeshesArray = [];
            var carBodyMesh;
            var platformMesh;
            var leftFrontDoorNode;
            var leftFrontDoorMesh;
            var rightFrontDoorNode;
            var rightFrontDoorMesh;
            var leftBackDoorNode;
            var leftBackDoorMesh;
            var rightBackDoorNode;
            var rightBackDoorMesh;
            var trunkLidNode;
            var trunkLidMesh;
            var trunkLidMeshInterior;
            var carHood;
            var ventilation;
            var suitcase1;
            var suitcase2;
            var suitcase3;
            var suitcase4;
            var suitcase5;
            var suitcaseArray = [];
            var isLeftFrontDoorOpened = false;
            var isLeftBackDoorOpened = false;
            var isRightFrontDoorOpened = false;
            var isRightBackDoorOpened = false;
            var isTrunkLidOpened = false;
            var isHoodOpened = false;
            ////////////
            // CAMERA //
            ////////////
            const mainCamera = new ArcRotateCamera("mainCamera", 1.0154, 1.530, 4.7, new Vector3(0, -1.5, 0), scene);
            mainCamera.minZ = 0.1;
            mainCamera.maxZ = 100000;
            mainCamera.lowerBetaLimit = 0;
            mainCamera.upperBetaLimit = 1.6;
            mainCamera.lowerRadiusLimit = 1;
            mainCamera.upperRadiusLimit = 12;
            mainCamera.wheelPrecision = 12;
            // const firstRowCamera = new ArcRotateCamera("firstRowCamera", 4.638, 1.5, 1.8, new Vector3(0, -1.3, 1), scene)
            // firstRowCamera.minZ = 0.1
            // const secondRowCamera = new ArcRotateCamera("secondRowCamera", 4, 1.5, 1, new Vector3(0, 0, 0), scene)
            // const thirdRowCamera = new ArcRotateCamera("thirdRowCamera", 4, 1.5, 1, new Vector3(0, 0, 0), scene)
            // // // This targets the camera to scene origin
            // mainCamera.setTarget( new Vector3(0, 1200, 0));
            // // This attaches the camera to the canvas
            mainCamera.attachControl(canvas, true);
            mainCamera.useFramingBehavior = true;
            /// POINTS FOR CAMERA CHANGE ANIM FUNCTION 
            let firstRowCameraConfig = { alpha: 4.36, beta: 1.409, radius: 1.4, x: 0, y: -1.3, z: 1 };
            let secondRowCameraConfig = { alpha: 4.678, beta: 1.476, radius: 2.2350, x: 0, y: -1.3, z: 1 };
            let thirdRowCameraConfig = { alpha: 4.7254, beta: 1.4560, radius: 5.4362, x: 0, y: -1.5, z: 1 };
            let zeroPoint = { alpha: 2.5577, beta: 1.3120, radius: 10, x: 0, y: -1.5, z: 0 };
            // load the environment file
            scene.environmentTexture = new CubeTexture(skyEnvironment, scene);
            scene.environmentIntensity = 2;
            scene.clearColor = new Color4(1, 1, 1, 1);
            scene.ambientColor = new Color3(1, 1, 1);
            // if not setting the envtext of the scene, we have to load the DDS module as well
            new EnvironmentHelper({
                skyboxSize: 20,
                skyboxTexture: skyEnvironment,
                createGround: false,
                createSkybox: true,
            }, scene);
            // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
            const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
            // Default intensity is 1. Let's dim the light a small amount
            light.intensity = 0.7;
            const importResult = await SceneLoader.ImportMeshAsync("", "", sceneGLB, scene, undefined, ".glb");
            // just scale it so we can see it better
            // for (let i = 0; i < importResult.meshes.length; i++){
            //     importResult.meshes[i].scaling.scaleInPlace(1);
            // }
            ////////////////////////////
            ////SETUP INIT ELEMENTS ////
            ///////////////////////////
            carNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_HELLCAT");
            carNode.position.y = -1.5;
            frogNode = scene.getTransformNodeByName("FROG");
            frogNode.position.y = -1.5;
            frogNode.rotationQuaternion = null;
            frogNode.rotation.x = 2;
            platformMesh = scene.getMeshByName("PLATFORM");
            platformMesh.position.y = -2.42;
            var platformMaterial = scene.getMaterialByName("PLATFORM");
            platformMaterial.albedoColor = new Color3(.6549, .6549, .6549);
            carBodyMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_primitive0");
            leftBackDoorNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_Body_Door_BL");
            leftBackDoorNode.rotationQuaternion = null;
            leftBackDoorMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BL_primitive0");
            leftFrontDoorNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_Body_Door_FL");
            leftFrontDoorNode.rotationQuaternion = null;
            leftFrontDoorMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FL_primitive0");
            rightBackDoorNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_Body_Door_BR");
            rightBackDoorNode.rotationQuaternion = null;
            rightBackDoorMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BR_primitive0");
            rightFrontDoorNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_Body_Door_FR");
            rightFrontDoorNode.rotationQuaternion = null;
            rightFrontDoorMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FR_primitive0");
            trunkLidNode = scene.getTransformNodeByName("AVE_DURANGO_SRT_Body_Door_B");
            trunkLidNode.rotationQuaternion = null;
            trunkLidMesh = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_B_primitive0");
            trunkLidMeshInterior = scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_B_primitive1");
            carHood = scene.getMeshByName("AVE_DURANGO_SRT_Hood");
            carHood.rotationQuaternion = null;
            ventilation = scene.getTransformNodeByName("VENTILATION");
            ventilation.position.y = -1.4;
            let whiteExMaterial = scene.getMaterialByName("AVE DURANGO SRT Body Main");
            let redExMaterial = scene.getMaterialByName("AVE DURANGO SRT Body Main Red");
            let greyExMaterial = scene.getMaterialByName("AVE DURANGO SRT Body Main Grey");
            let redInMaterial = scene.getMaterialByName("AVE DURANGO SRT Interior");
            let blackInMaterial = scene.getMaterialByName("AVE DURANGO SRT Interior Black");
            let exteriorMeshArray = [scene.getMeshByName("AVE_DURANGO_SRT_Hood")];
            let interiorMeshArray = [
                scene.getMeshByName("AVE_DURANGO_SRT_Interior"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BL"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BR"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_FL"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_FR"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_ML"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_MR"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_B_primitive1"),
                scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FL_primitive1"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BL_primitive1"),
                scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FR_primitive1"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BR_primitive1"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Storage_B"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Storage_F"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_ML_1"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_ML_2"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_MR_1"),
                scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_MR_2"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BR_1"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BR_2"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BL_1"), scene.getMeshByName("AVE_DURANGO_SRT_Interior_Seat_BL_2"),
            ];
            let leftBackDoorNodeArray = [scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BL_Handle_IN"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BL_Handle_OUT"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BL_primitive0")];
            let leftFrontDoorNodeArray = [scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FL_Handle_IN"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FL_Handle_OUT"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FL_primitive0")];
            let rightBackDoorNodeArray = [scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BR_Handle_IN"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BR_Handle_OUT"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_BR_primitive0")];
            let rightFrontDoorNodeArray = [scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FR_Handle_IN"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FR_Handle_OUT"), scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_FR_primitive0")];
            let trunkLidNodeArray = [scene.getMeshByName("AVE_DURANGO_SRT_Body_Door_B_primitive0")];
            exteriorMeshArray.push(carBodyMesh);
            for (const mesh of leftBackDoorNodeArray) {
                exteriorMeshArray.push(mesh);
            }
            for (const mesh of leftFrontDoorNodeArray) {
                exteriorMeshArray.push(mesh);
            }
            for (const mesh of rightBackDoorNodeArray) {
                exteriorMeshArray.push(mesh);
            }
            for (const mesh of rightFrontDoorNodeArray) {
                exteriorMeshArray.push(mesh);
            }
            for (const mesh of trunkLidNodeArray) {
                exteriorMeshArray.push(mesh);
            }
            console.log(exteriorMeshArray);
            function getAllMeshes(Node) {
                let meshChildren = Node.getChildMeshes(false);
                return meshChildren;
            }
            platformMesh.receiveShadows = true;
            carNodeMeshesArray = getAllMeshes(carNode);
            console.log(carNodeMeshesArray);
            const mirrorMaterial = scene.getMaterialByName("PLATFORM");
            mirrorMaterial.opacityFresnel = false;
            mirrorMaterial.shadowLevel = 1;
            const mirrorMaterialReflectionTexture = new MirrorTexture('mirror', 1024, scene);
            mirrorMaterialReflectionTexture.mirrorPlane = new Plane(0, -1, 0, -2.36);
            for (const mesh of carNodeMeshesArray) {
                mirrorMaterialReflectionTexture.renderList.push(mesh);
            }
            let skybox = scene.getMeshByName("BackgroundSkybox");
            mirrorMaterialReflectionTexture.renderList.push(skybox);
            mirrorMaterial.reflectionTexture = mirrorMaterialReflectionTexture;
            mirrorMaterial.reflectionTexture.level = .7;
            mirrorMaterial.reflectionFresnel = true;
            mirrorMaterial.reflectionStandardFresnelWeight = .65;
            platformMesh.material = mirrorMaterial;
            suitcase1 = scene.getMeshByName("SUITCASE.01"), suitcase2 = scene.getMeshByName("SUITCASE.02"), suitcase3 = scene.getMeshByName("SUITCASE.03"), suitcase4 = scene.getMeshByName("SUITCASE.04"), suitcase5 = scene.getMeshByName("SUITCASE.05");
            suitcase1.position.y = -2;
            suitcase2.position.y = -2;
            suitcase3.position.y = -2;
            suitcase4.position.y = -2;
            suitcase5.position.y = -2;
            suitcase1.position.z = -3.2;
            suitcase2.position.z = -3.2;
            suitcase3.position.z = -3.2;
            suitcase4.position.z = -3.2;
            suitcase5.position.z = -3.2;
            suitcase1.isVisible = false;
            suitcase2.isVisible = false;
            suitcase3.isVisible = false;
            suitcase4.isVisible = false;
            suitcase5.isVisible = false;
            suitcaseArray = [suitcase1, suitcase2, suitcase3, suitcase4, suitcase5];
            /////////////////
            // INDIC GROUP //
            /////////////////
            let indicGroup;
            let _firstRowIndic;
            let _secondRowIndic;
            let _thirdRowIndic;
            const indicMaterial = new StandardMaterial("indic_material");
            indicMaterial.diffuseColor = Color3.FromHexString("#c93038");
            indicMaterial.emissiveColor = Color3.FromHexString("#c93038");
            indicMaterial.specularColor.copyFromFloats(0, 0, 0);
            indicMaterial.alpha = 0.8;
            indicGroup = new ShapeIndicatorGroup(new SphereIndicatorShapeDefinition(.2, Color3.FromHexString("#c93038"), 1, indicMaterial, 64, false), scene);
            // main indicators
            const indicMeshes = indicGroup.addIndicators([
                {
                    name: "firstRow",
                    pos: new Vector3(-1.1, -1.35, -.1),
                    rot: new Vector3(Math.PI / 2, 0, 0),
                },
                {
                    name: "secondRow",
                    pos: new Vector3(-1.1, -1.3, -1.2),
                    rot: new Vector3(Math.PI / 2, 0, 0),
                },
                {
                    name: "thirdRow",
                    pos: new Vector3(0, -1.3, -2.9),
                    rot: new Vector3(Math.PI / 2, 0, 0),
                }
            ]);
            _firstRowIndic = indicMeshes[0];
            _secondRowIndic = indicMeshes[1];
            _thirdRowIndic = indicMeshes[2];
            for (const m of indicMeshes) {
                m.isPickable = true;
            }
            //////////////////////////////////
            //ANNOTATIONS PROTOTYPE ? GROUP //
            ////////////////////////////////
            let anno;
            let annoHtmlString = `<h2>SUPER DUPER ANNO !</h2>
        <p>Be Amazed with <b>ThisANNO</b> - beautiful and functional, this feature extends <br>
        <div class='menu-list' id='hydroBreeze'>BLABLABLA !</div></p>`;
            let closeClass1 = "anno1close";
            const newAnno = annoFunction(anno, annoHtmlString, closeClass1);
            let anno2;
            let h2 = "drugie anno";
            let p2 = "work work work";
            let closeClass2 = "anno2close";
            const newAnno2 = annoFunction(anno2, annoHtmlString, closeClass2);
            const newAnno2CloseButon = document.body.querySelector("anno2close");
            var anno1ScreenCoords;
            var anno2ScreenCoords;
            scene.onAfterRenderObservable.add(() => {
                ///////////////////////////////////////
                ////////////HIDE LOADING LAYER  ////////
                ///////////////////////////////////////
                engine.hideLoadingUI();
                ///////////////////////////////////////
                ////////////POSITIONING OF ANNO ////////
                ///////////////////////////////////////
                anno1ScreenCoords = Vector3.Project(Vector3.Zero(), _firstRowIndic.getWorldMatrix(), scene.getTransformMatrix(), mainCamera.viewport.toGlobal(engine.getRenderWidth(true), engine.getRenderHeight(true)));
                anno2ScreenCoords = Vector3.Project(Vector3.Zero(), _secondRowIndic.getWorldMatrix(), scene.getTransformMatrix(), mainCamera.viewport.toGlobal(engine.getRenderWidth(true), engine.getRenderHeight(true)));
                newAnno.annotation.style.transform = "translate3d(calc(" + (anno1ScreenCoords.x) + "px - 100%), calc(" + (anno1ScreenCoords.y) + "px - 100%), 10px)";
                newAnno2.annotation.style.transform = "translate3d(calc(" + (anno2ScreenCoords.x) + "px - 100%), calc(" + (anno2ScreenCoords.y) + "px - 100%), 10px)";
            });
            ////////////////////////////
            //ACTION MANAGER PROTOTYPE //
            /////////////////////////////
            var dragged = false;
            //Detects mouse up
            canvas.addEventListener("pointerup", function () {
                console.log("Mouse UP!");
                dragged = false;
            });
            //Detects mouse down
            canvas.addEventListener("pointerdown", function () {
                console.log("Mouse DOWN!");
                dragged = true;
            });
            //Detects click
            canvas.addEventListener("click", function () {
                console.log("Mouse Click!");
            });
            const setVisible = (value) => {
                _firstRowIndic.isVisible = value;
                _secondRowIndic.isVisible = value;
                _thirdRowIndic.isVisible = value;
            };
            //INDIC ACTIONS MANAGER 
            _firstRowIndic.actionManager = new ActionManager(scene);
            _firstRowIndic.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {
                // Piwik PRO analytics tag
                // @ts-ignore
                _paq.push(["trackEvent", 'Dummy indic clicked', 'Main']);
                setVisible(false);
                if (isLeftFrontDoorOpened === false) {
                    let leftFrontDoorOpenAnim = Animation.CreateAndStartAnimation("anim", leftFrontDoorNode, "rotation.y", 66, 100, 0, -1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        changeCamera(mainCamera, scene, firstRowCameraConfig, 50, () => {
                            cameraValuesChange(mainCamera, 1.2, 1.4, 1.21, 1.6, 4.2, 5.26);
                            backButton.classList.remove("is-hidden");
                        });
                        isLeftFrontDoorOpened = true;
                        leftFrontDoorOpenAnim.disposeOnEnd = true;
                    });
                }
            }));
            _secondRowIndic.actionManager = new ActionManager(scene);
            _secondRowIndic.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {
                // Piwik PRO analytics tag
                // @ts-ignore
                _paq.push(["trackEvent", 'anotherDummy indic clicked', 'Main']);
                setVisible(false);
                if (isLeftBackDoorOpened === false) {
                    let leftBackDoorOpenAnim = Animation.CreateAndStartAnimation("anim", leftBackDoorNode, "rotation.y", 66, 100, 0, -1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        changeCamera(mainCamera, scene, secondRowCameraConfig, 50, () => {
                            cameraValuesChange(mainCamera, 1.8, 2.9, 1.294, 1.6, 4.36, 5);
                            backButton.classList.remove("is-hidden");
                        });
                        isLeftBackDoorOpened = true;
                        leftBackDoorOpenAnim.disposeOnEnd = true;
                    });
                }
            }));
            _thirdRowIndic.actionManager = new ActionManager(scene);
            _thirdRowIndic.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function () {
                // Piwik PRO analytics tag
                // @ts-ignore
                _paq.push(["trackEvent", 'anotherDummy indic clicked', 'Main']);
                setVisible(false);
                if (isTrunkLidOpened === false) {
                    let trunkLidOpenAnim = Animation.CreateAndStartAnimation("anim", trunkLidNode, "rotation.x", 50, 100, 0, 1.5, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        changeCamera(mainCamera, scene, thirdRowCameraConfig, 50, () => {
                            cameraValuesChange(mainCamera, 4.8, 6, 1.396, 1.6, 4.5, 4.9);
                            backButton.classList.remove("is-hidden");
                            packButton.classList.remove("is-hidden");
                            suitcase1.isVisible = true;
                            suitcase2.isVisible = true;
                            suitcase3.isVisible = true;
                            suitcase4.isVisible = true;
                            suitcase5.isVisible = true;
                        });
                        trunkLidOpenAnim.disposeOnEnd = true;
                        isTrunkLidOpened = true;
                    });
                }
            }));
            //CAR HOOD////
            carHood.actionManager = new ActionManager(scene);
            carHood.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
                if (isHoodOpened === false) {
                    let carHoodOpenAnim = Animation.CreateAndStartAnimation("anim", carHood, "rotation.x", 50, 100, 0, -1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        isHoodOpened = true;
                        carHoodOpenAnim.disposeOnEnd = true;
                        let froganim = Animation.CreateAndStartAnimation("anim", frogNode, 'rotation.x', 75, 100, 2, 3, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                            froganim.disposeOnEnd = true;
                        });
                    });
                    return;
                }
                if (isHoodOpened === true) {
                    let froganim = Animation.CreateAndStartAnimation("anim", frogNode, 'rotation.x', 75, 100, 3, 2, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        froganim.disposeOnEnd = true;
                    });
                    let carHoodOpenAnim = Animation.CreateAndStartAnimation("anim", carHood, "rotation.x", 50, 100, -1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        isHoodOpened = false;
                        carHoodOpenAnim.disposeOnEnd = true;
                    });
                    return;
                }
            }));
            // //LEFT FRONT DOOR ///
            // leftFrontDoorMesh.actionManager = new ActionManager(scene);
            // leftFrontDoorMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
            //     if (isLeftFrontDoorOpened === false) {
            //         let leftFrontDoorOpenAnim = Animation.CreateAndStartAnimation("anim", leftFrontDoorNode, "rotation.y", 50, 100, 0, -1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isLeftFrontDoorOpened = true
            //             leftFrontDoorOpenAnim.disposeOnEnd = true;
            //         });
            //         return;
            //     }
            //     if (isLeftFrontDoorOpened === true) {
            //         let leftFrontDoorCloseAnim = Animation.CreateAndStartAnimation("anim", leftFrontDoorNode, "rotation.y", 50, 100, -1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isLeftFrontDoorOpened = false
            //             leftFrontDoorCloseAnim.disposeOnEnd = true
            //             _firstRowIndic.isVisible = false
            //         });
            //         return;
            //     }
            // }))
            // ///LEFT BACK DOOR ///
            // leftBackDoorMesh.actionManager = new ActionManager(scene);
            // leftBackDoorMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
            //     if (isLeftBackDoorOpened === false) {
            //         let leftBackDoorOpenAnim = Animation.CreateAndStartAnimation("anim", leftBackDoorNode, "rotation.y", 50, 100, 0, -1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isLeftBackDoorOpened = true
            //             leftBackDoorOpenAnim.disposeOnEnd = true
            //             _secondRowIndic.isVisible = true;
            //         });
            //         return;
            //     }
            //     if (isLeftBackDoorOpened === true) {
            //         let leftBackDoorCloseAnim = Animation.CreateAndStartAnimation("anim", leftBackDoorNode, "rotation.y", 50, 100, -1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isLeftBackDoorOpened = false
            //             leftBackDoorCloseAnim.disposeOnEnd = true
            //             _secondRowIndic.isVisible = false;
            //         });
            //         return;
            //     }
            // }))
            // RIGHT FRONT DOOR ///
            rightFrontDoorMesh.actionManager = new ActionManager(scene);
            rightFrontDoorMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
                if (isRightFrontDoorOpened === false) {
                    let rightFrontDoorOpenAnim = Animation.CreateAndStartAnimation("anim", rightFrontDoorNode, "rotation.y", 50, 100, 0, 1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        isRightFrontDoorOpened = true;
                        rightFrontDoorOpenAnim.disposeOnEnd = true;
                    });
                    return;
                }
                if (isRightFrontDoorOpened === true) {
                    let rightFrontDoorCloseAnim = Animation.CreateAndStartAnimation("anim", rightFrontDoorNode, "rotation.y", 50, 100, 1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        isRightFrontDoorOpened = false;
                        rightFrontDoorCloseAnim.disposeOnEnd = true;
                    });
                    return;
                }
            }));
            ///RIGHT BACK DOOR ///
            rightBackDoorMesh.actionManager = new ActionManager(scene);
            rightBackDoorMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
                if (isRightBackDoorOpened === false) {
                    let rightBackDoorOpenAnim = Animation.CreateAndStartAnimation("anim", rightBackDoorNode, "rotation.y", 50, 100, 0, 1, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        rightBackDoorOpenAnim.disposeOnEnd = true;
                        isRightBackDoorOpened = true;
                    });
                    return;
                }
                if (isRightBackDoorOpened === true) {
                    let rightBackDoorCloseAnim = Animation.CreateAndStartAnimation("anim", rightBackDoorNode, "rotation.y", 50, 100, 1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                        isRightBackDoorOpened = false;
                        rightBackDoorCloseAnim.disposeOnEnd = true;
                    });
                    return;
                }
            }));
            ///TRUNK LID ANIM /////
            // trunkLidMesh.actionManager = new ActionManager(scene);
            // trunkLidMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
            //     if (isTrunkLidOpened === false) {
            //         let trunkLidOpenAnim = Animation.CreateAndStartAnimation("anim", trunkLidNode, "rotation.x", 50, 100, 0, 1.5, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             trunkLidOpenAnim.disposeOnEnd = true
            //             isTrunkLidOpened = true
            //             _thirdRowIndic.isVisible = true
            //         });
            //         return;
            //     }
            //     if (isTrunkLidOpened === true) {
            //         let trunkLidCloseAnim = Animation.CreateAndStartAnimation("anim", trunkLidNode, "rotation.x", 50, 100, 1.5, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isTrunkLidOpened = false
            //             trunkLidCloseAnim.disposeOnEnd = true
            //             _thirdRowIndic.isVisible = false
            //         });
            //         return;
            //     }
            // }))
            // trunkLidMeshInterior.actionManager = new ActionManager(scene)
            // trunkLidMeshInterior.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
            //     if (isTrunkLidOpened === true) {
            //         let trunkLidCloseAnim = Animation.CreateAndStartAnimation("anim", trunkLidNode, "rotation.x", 50, 100, 1.5, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
            //             isTrunkLidOpened = false
            //             trunkLidCloseAnim.disposeOnEnd = true
            //         });
            //         return;
            //     }
            // }))
            /////EXTERIOR COLOR CHANGE ///////////
            //show buttons//
            dodgeLogo.classList.remove("is-hidden");
            whiteExButton.classList.remove("is-hidden");
            whiteExButton.classList.add("active");
            redExButton.classList.remove("is-hidden");
            greyExButton.classList.remove("is-hidden");
            redInButton.classList.remove("is-hidden");
            redInButton.classList.add("active");
            blackInButton.classList.remove("is-hidden");
            interiorH2.classList.remove("is-hidden");
            exteriorH2.classList.remove("is-hidden");
            document.getElementById("bgdiv").classList.remove("is-hidden");
            scene.getMeshByName("BODY_GREY").isVisible = false;
            scene.getMeshByName("BODY_RED").isVisible = false;
            scene.getMeshByName("INTERIOR_BLACK").isVisible = false;
            redExButton.onclick = () => {
                if (whiteExButton.classList.contains("active"))
                    whiteExButton.classList.remove("active");
                if (greyExButton.classList.contains("active"))
                    greyExButton.classList.remove("active");
                redExButton.classList.add("active");
                greyExButton.classList.remove("active");
                console.log("clicked redEx");
                for (const mesh of exteriorMeshArray) {
                    mesh.material = redExMaterial;
                }
                return;
            };
            greyExButton.onclick = () => {
                if (whiteExButton.classList.contains("active"))
                    whiteExButton.classList.remove("active");
                if (redExButton.classList.contains("active"))
                    redExButton.classList.remove("active");
                greyExButton.classList.add("active");
                console.log("clicked greyEx");
                for (const mesh of exteriorMeshArray) {
                    mesh.material = greyExMaterial;
                }
                return;
            };
            whiteExButton.onclick = () => {
                console.log("clicked whiteEx");
                if (redExButton.classList.contains("active"))
                    redExButton.classList.remove("active");
                if (greyExButton.classList.contains("active"))
                    greyExButton.classList.remove("active");
                whiteExButton.classList.add("active");
                for (const mesh of exteriorMeshArray) {
                    mesh.material = whiteExMaterial;
                }
                return;
            };
            ////INTERIOR COLOR CHANGE //////
            redInButton.onclick = () => {
                blackInButton.classList.remove("active");
                redInButton.classList.add("active");
                console.log("clicked redIn");
                for (const mesh of interiorMeshArray) {
                    mesh.material = redInMaterial;
                }
            };
            blackInButton.onclick = () => {
                blackInButton.classList.add("active");
                redInButton.classList.remove("active");
                console.log("clicked blackIn");
                for (const mesh of interiorMeshArray) {
                    mesh.material = blackInMaterial;
                }
            };
            packButton.onclick = () => {
                packButton.classList.add("is-hidden");
                for (const mesh of suitcaseArray) {
                    setTimeout(() => {
                        let anim1 = Animation.CreateAndStartAnimation("anim", mesh, "position.y", 75, 100, -2, -1.3, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                            anim1.disposeOnEnd = true;
                            let anim2 = Animation.CreateAndStartAnimation("anim", mesh, "position.z", 75, 100, -3.2, -2.414, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                                anim2.disposeOnEnd = true;
                            });
                        });
                    }, 0.3);
                }
            };
            backButton.onclick = () => {
                backButton.classList.add("is-hidden");
                packButton.classList.add("is-hidden");
                cameraValuesChange(mainCamera, 1, 12, 0, 1.6, -10, 10);
                changeCamera(mainCamera, scene, zeroPoint, 50, () => {
                    carWrapper.classList.remove("is-hidden");
                    console.log("change camera complete");
                    for (const mesh of suitcaseArray) {
                        mesh.isVisible = false;
                        mesh.position.y = -2;
                        mesh.position.z = -3.2;
                    }
                    if (isTrunkLidOpened === true) {
                        let trunkLidCloseAnim = Animation.CreateAndStartAnimation("anim", trunkLidNode, "rotation.x", 75, 100, 1.5, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                            isTrunkLidOpened = false;
                            trunkLidCloseAnim.disposeOnEnd = true;
                            setVisible(true);
                        });
                    }
                    if (isLeftFrontDoorOpened === true) {
                        let leftFrontDoorCloseAnim = Animation.CreateAndStartAnimation("anim", leftFrontDoorNode, "rotation.y", 75, 100, -1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                            isLeftFrontDoorOpened = false;
                            leftFrontDoorCloseAnim.disposeOnEnd = true;
                            setVisible(true);
                            carWrapper.classList.remove("is-hidden");
                        });
                    }
                    if (isLeftBackDoorOpened === true) {
                        let leftBackDoorCloseAnim = Animation.CreateAndStartAnimation("anim", leftBackDoorNode, "rotation.y", 75, 100, -1, 0, Animation.ANIMATIONLOOPMODE_CONSTANT, new CircleEase(), () => {
                            isLeftBackDoorOpened = false;
                            leftBackDoorCloseAnim.disposeOnEnd = true;
                            setVisible(true);
                            carWrapper.classList.remove("is-hidden");
                        });
                    }
                });
            };
            return scene;
        };
    }
    get createScene() {
        return this._createScene;
    }
    set createScene(value) {
        this._createScene = value;
    }
}
export default new AppModeController();
