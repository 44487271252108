export const annoFunction = (annotation, htmlAnnoString, closeBTNClassName) => {
    var annotation = document.getElementById(`${annotation}`);
    if (annotation) {
        annotation.parentNode.removeChild(annotation);
    }
    annotation = document.createElement("div");
    annotation.id = `${annotation}`;
    annotation.className = "label-component";
    annotation.style.transform = "translate3d(40vw, 30px, 0px)";
    const closeBtn = document.createElement("a");
    closeBtn.textContent = "✕";
    closeBtn.className = closeBTNClassName;
    annotation.innerHTML = htmlAnnoString;
    annotation.appendChild(closeBtn);
    document.body.appendChild(annotation);
    annotation.classList.add("is-hidden");
    const svgLineCornerSize = 30;
    const htmlElemString = `
        <svg class="standard-annotation-corner-line-svg">
        <line x1="0" y1="0" x2="${svgLineCornerSize}" y2="${svgLineCornerSize}"
            style="stroke:rgba(255,255,255,0.7);stroke-width:0.9"
        />
    </svg>
`;
    let annoLine;
    let svgLine;
    const utilDiv = document.createElement("div");
    utilDiv.innerHTML = htmlElemString.trim();
    annoLine = utilDiv.firstChild;
    svgLine = annoLine.querySelector(".standard-annotation-corner-line-svg line");
    annotation.appendChild(annoLine);
    return {
        'annotation': annotation,
        'closeButton': closeBtn,
        'annoLine': annoLine
    };
};
