import { CubicEase, Animation, EasingFunction } from "@babylonjs/core";
export function cameraValuesChange(camera, radiusMin, radiusMax, betaMin, betaMax, alphaMin, alphaMax) {
    camera.lowerBetaLimit = betaMin;
    camera.upperBetaLimit = betaMax;
    camera.lowerRadiusLimit = radiusMin;
    camera.upperRadiusLimit = radiusMax;
    camera.lowerAlphaLimit = alphaMin;
    camera.upperAlphaLimit = alphaMax;
}
// camera anim function 
export function changeCamera(camera, scene, targetCameraPosition, framesRate, action) {
    const startFrame = 0;
    const endFrame = 100;
    const frameRate = framesRate;
    let cameraAlpha;
    let cameraBeta;
    let cameraRadius;
    let cameraTargetX;
    let cameraTargetY;
    let cameraTargetZ;
    let easingFunction;
    const cameraAnim = {
        cameraAlpha,
        cameraBeta,
        cameraRadius,
        cameraTargetX,
        cameraTargetY,
        cameraTargetZ,
        easingFunction
    };
    cameraAnim.cameraAlpha = new Animation("cameraAlpha", "alpha", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    cameraAnim.cameraBeta = new Animation("cameraBeta", "beta", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    cameraAnim.cameraRadius = new Animation("cameraBeta", "radius", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    cameraAnim.cameraTargetX = new Animation("cameraTargetX", "target.x", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    cameraAnim.cameraTargetY = new Animation("cameraTargetY", "target.y", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    cameraAnim.cameraTargetZ = new Animation("cameraTargetZ", "target.z", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    camera.alpha = camera.alpha % (Math.PI * 2);
    if (camera.alpha < 0)
        camera.alpha += Math.PI * 2;
    const alphaKeyFrames = [{ frame: 0, value: camera.alpha },
        { frame: endFrame, value: targetCameraPosition.alpha }];
    const betaKeyFrames = [{ frame: 0, value: camera.beta },
        { frame: endFrame, value: targetCameraPosition.beta }];
    const radiusKeyFrames = [{ frame: 0, value: camera.radius },
        { frame: endFrame, value: targetCameraPosition.radius }];
    const targetXKeyFrames = [{ frame: 0, value: camera.target.x },
        { frame: endFrame, value: targetCameraPosition.x }];
    const targetYKeyFrames = [{ frame: 0, value: camera.target.y },
        { frame: endFrame, value: targetCameraPosition.y }];
    const targetZKeyFrames = [{ frame: 0, value: camera.target.z },
        { frame: endFrame, value: targetCameraPosition.z }];
    cameraAnim.cameraAlpha.setKeys(alphaKeyFrames);
    cameraAnim.cameraBeta.setKeys(betaKeyFrames);
    cameraAnim.cameraRadius.setKeys(radiusKeyFrames);
    cameraAnim.cameraTargetX.setKeys(targetXKeyFrames);
    cameraAnim.cameraTargetY.setKeys(targetYKeyFrames);
    cameraAnim.cameraTargetZ.setKeys(targetZKeyFrames);
    // set up easing function and easing mode
    cameraAnim.easingFunction = new CubicEase();
    let easingMode = EasingFunction.EASINGMODE_EASEINOUT;
    cameraAnim.easingFunction.setEasingMode(easingMode);
    // set up easing
    cameraAnim.cameraAlpha.setEasingFunction(cameraAnim.easingFunction);
    cameraAnim.cameraBeta.setEasingFunction(cameraAnim.easingFunction);
    cameraAnim.cameraRadius.setEasingFunction(cameraAnim.easingFunction);
    cameraAnim.cameraTargetX.setEasingFunction(cameraAnim.easingFunction);
    cameraAnim.cameraTargetY.setEasingFunction(cameraAnim.easingFunction);
    cameraAnim.cameraTargetZ.setEasingFunction(cameraAnim.easingFunction);
    scene.beginDirectAnimation(camera, [cameraAnim.cameraRadius, cameraAnim.cameraAlpha, cameraAnim.cameraBeta,
        cameraAnim.cameraTargetX, cameraAnim.cameraTargetY, cameraAnim.cameraTargetZ], startFrame, endFrame, false, 1, action);
}
