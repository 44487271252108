export class AbstractIndicatorGroup {
    constructor(indicSrcMesh, scene) {
        this.scene = scene;
        this.groupVisible = true;
        this.indicMeshesState = {};
        this.indicSrcMesh = indicSrcMesh;
        this.indicSrcMesh.setEnabled(false);
    }
    setGroupVisible(visible, instant) {
        if (visible == this.groupVisible)
            return;
        this.groupVisible = visible;
        this.syncIndicMeshesVisibility(instant, 0.2);
    }
    setIndicatorVisible(name, visible) {
        this.indicMeshesState[name].visible = visible;
        this.syncIndicMeshesVisibility(false, 0.5);
    }
    addIndicator(name, pos, rot, parent, visible) {
        const im = this.stateCreateIndicMesh(name, pos, rot, parent, visible);
        this.syncIndicMeshesVisibility(true, 0);
        return im;
    }
    /**
     * more efficient for adding many at once
     */
    addIndicators(indicators) {
        const r = [];
        for (const i of indicators) {
            const im = this.stateCreateIndicMesh(i.name, i.pos, i.rot, i.parent, i.visible);
            r.push(im);
        }
        this.syncIndicMeshesVisibility(true, 0);
        return r;
    }
    stateCreateIndicMesh(name, pos, rot, parent, visible = true) {
        const im = this.indicSrcMesh.clone(name);
        im.setEnabled(true);
        im.position = pos;
        im.rotation = rot;
        if (parent)
            im.parent = parent;
        this.indicMeshesState[im.name] = { mesh: im, visible };
        return im;
    }
    syncIndicMeshesVisibility(instant, tweenDuration) {
        if (instant) {
            for (const indicMeshName in this.indicMeshesState) {
                const indicMeshState = this.indicMeshesState[indicMeshName];
                if (!indicMeshState.visible || !this.groupVisible) {
                    indicMeshState.mesh.visibility = 0;
                    indicMeshState.mesh.setEnabled(false);
                }
                else {
                    indicMeshState.mesh.visibility = 1;
                    indicMeshState.mesh.setEnabled(true);
                }
            }
        }
        else {
            for (const indicMeshName in this.indicMeshesState) {
                const indicMeshState = this.indicMeshesState[indicMeshName];
                const indicMesh = indicMeshState.mesh;
                const visible = indicMeshState.visible;
                if (!visible || !this.groupVisible) {
                    TweenLite.to(indicMesh, tweenDuration, {
                        visibility: 0,
                        onComplete: this.utilSetMeshEnabledByVis,
                        onCompleteParams: [indicMesh]
                    });
                }
                else {
                    TweenLite.to(indicMesh, tweenDuration, { visibility: 1 });
                    indicMesh.setEnabled(true);
                }
            }
        }
    }
    utilSetMeshEnabledByVis(mesh) {
        if (mesh.visibility)
            mesh.setEnabled(true);
        else
            mesh.setEnabled(false);
    }
}
